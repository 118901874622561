<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOpen"
          width="500"
          persistent
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :ripple="false"
              fab
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="currentForm = { ...defaultCurrentFormValue }"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              {{ currentForm.id ? 'バックユニット編集' : '新規バックユニット' }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="currentForm.name"
                :error="!isValid.name"
                label="名前"
              />

              <v-textarea
                v-model="currentForm.description"
                label="説明"
                rows="2"
                :prepend-icon="icons.mdiCommentQuoteOutline"
              />

              <v-select
                v-model="currentForm.evaluationCycleSpan"
                :items="evaluationCycleSpans"
                item-value="value"
                item-text="text"
                label="評価サイクル"
                hide-details
                :menu-props="{ auto: true, offsetY: true }"
              />

              <v-row class="mt-8">
                <v-col cols="12">
                  <h4 class="mb-4">
                    構成要素
                  </h4>

                  <yona-has-many-v-select
                    v-model="currentForm.vendingRebatesAttributes"
                    label="商品バック"
                    parent-name="rebateSystemUnit"
                    :parent-id="currentForm.id"
                    :children="rebatesByType.VendingRebate"
                    :item-text="item => item.meta.itemText"
                    child-name="rebate"
                    child-type="vendingRebate"
                    :error="!isValid.rebates"
                  />

                  <yona-has-many-v-select
                    v-model="currentForm.salesRebatesAttributes"
                    label="売上バック"
                    parent-name="rebateSystemUnit"
                    :parent-id="currentForm.id"
                    :children="rebatesByType.SalesRebate"
                    :item-text="item => item.meta.itemText"
                    child-name="rebate"
                    child-type="salesRebate"
                    :error="!isValid.rebates"
                  />

                  <yona-has-many-v-select
                    v-model="currentForm.referringRebatesAttributes"
                    label="紹介バック"
                    parent-name="rebateSystemUnit"
                    :parent-id="currentForm.id"
                    :children="rebatesByType.ReferringRebate"
                    :item-text="item => item.meta.itemText"
                    child-name="rebate"
                    child-type="referringRebate"
                    :error="!isValid.rebates"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                color="primary"
                :loading="isSubmitting"
                :disabled="!(isValid.name && isValid.rebates)"
                @click="submit"
              >
                {{ currentForm.id ? '更新' : '作成' }}
              </v-btn>
              <v-btn
                :ripple="false"
                color="blue darken-1"
                text
                @click="cancelForm"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="`${JSON.stringify(rebateSystemUnits)}`"
        :headers="headers"
        :items="rebateSystemUnits"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
        show-group-by
        sort-by="groupPosition"
        :expanded="rebateSystemUnits"
      >
        <template #[`group.header`]="{ group, remove }">
          <td
            :ref="groupHeader"
            :colspan="headers.length"
            @click="remove"
          >
            <span class="mr-4">評価サイクル</span>
            <strong>{{ groupNames[group] || '随時' }}</strong>
          </td>
        </template>

        <template #[`item.groupPosition`]="{item}">
          <v-chip
            :color="evaluationCycleSpanColors[groupNames[item.groupPosition]] || 'error'"
            :class="`${evaluationCycleSpanColors[groupNames[item.groupPosition]] || 'error'}--text`"
            class="v-chip-light-bg"
          >
            {{ groupTexts[groupNames[item.groupPosition]] || '随時' }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <span class="d-flex align-center justify-end">
            <v-btn
              :ripple="false"
              icon
              @click="
                currentForm = { id: item.id, ...item.attributes, ...setRebateSystemUnitRebatesByType(item) };
                isFormOpen = true"
            >
              <v-icon small>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>

            <yona-edit-dialog
              title="削除の確認"
              btn-color="error"
              save-text="同意の上で削除"
              @save="destroy(item.id)"
            >
              <template #append-outer-display-name>
                <v-btn
                  icon
                  :ripple="false"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <template #input>
                <span>
                  削除を実行してもよろしいですか
                </span>
              </template>
            </yona-edit-dialog>
          </span>
        </template>

        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col
                sm="12"
                md="6"
                class="ps-4"
              >
                <v-row>
                  <v-col
                    class="pb-0 d-flex align-center"
                    style="min-height: 42px"
                  >
                    構成要素
                    ({{ item.attributes.rebateSystemUnitRebates.data.length }})
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-treeview
                      :items="treeviewItems(item)"
                    >
                      <template #label="{ item: node }">
                        <v-chip-group
                          v-if="!node.name"
                          column
                        >
                          <v-chip
                            v-for="(rebate, rebateIdx) in node.rebates"
                            :key="`rebateSystemUnit-${item.id}-${rebate.type}s[${rebateIdx}]`"
                          >
                            {{ rebate.meta.itemText }}
                          </v-chip>
                        </v-chip-group>

                        <span v-else>{{ node.name }}</span>
                      </template>
                    </v-treeview>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                sm="12"
                md="6"
                class="ps-4"
              >
                <v-row>
                  <v-col class="pb-0 d-flex align-center">
                    <span class="mr-2">
                      発動条件
                      ({{ item.attributes.logics.data.length }})
                    </span>

                    <logic-builder
                      :key="`unit-${item.id}-logics-${JSON.stringify(item.attributes.logics.data)}`"
                      :system-unit-id="+item.id"
                      system-unit-type="rebateSystemUnit"
                      :system-unit-name="item.attributes.name"
                      :logics="item.attributes.logics.data"
                      :vending-point-conditions="vendingPointConditions"
                      :sales-conditions="salesConditions"
                      :contribution-ratio-conditions="contributionRatioConditions"
                      :vending-size-conditions="vendingSizeConditions"
                      :referring-size-conditions="referringSizeConditions"
                      @changed="currentForm = { id: item.id, logicsAttributes: $event }"
                      @submit="submit"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-treeview
                      v-if="item.attributes.logics.data.length > 0"
                      :items="[{
                        id: `unit-${item.id}-logics`,
                        name: '内容',
                        children: [{ logics: item.attributes.logics.data }]
                      }]"
                    >
                      <template #label="{ item: node }">
                        <v-chip-group
                          v-if="node.logics"
                          column
                        >
                          <span
                            v-for="(logic, logicIdx) in node.logics"
                            :key="`unit-${item.id}-${JSON.stringify(logic)}`"
                          >
                            <v-chip>
                              {{ logic.meta.conditionText }}
                            </v-chip>

                            <v-chip
                              v-if="logicIdx !== node.logics.length - 1"
                              color="primary"
                              class="v-chip-light-bg primary--text"
                            >
                              {{ logic.meta.gateText }}
                            </v-chip>
                          </span>
                        </v-chip-group>

                        <span v-else>{{ node.name }}</span>
                      </template>
                    </v-treeview>

                    <p
                      v-else
                      class="ms-6 secondary--text"
                    >
                      常に真
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiCommentQuoteOutline,
  mdiCubeOutline,
} from '@mdi/js'
import {
  map,
  findIndex,
  cloneDeep,
  each,
  flatMap,
  some,
  values,
  every,
} from 'lodash'
import RebateSystemUnitApi from '@/api/admin/RebateSystemUnit'
import VendingPointConditionApi from '@/api/admin/VendingPointCondition'
import SalesConditionApi from '@/api/admin/SalesCondition'
import ContributionRatioConditionApi from '@/api/admin/ContributionRatioCondition'
import VendingSizeConditionApi from '@/api/admin/VendingSizeCondition'
import ReferringSizeConditionApi from '@/api/admin/ReferringSizeCondition'
import RebateApi from '@/api/admin/Rebate'
import useCompInit from '@/views/composable/useCompInit'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import YonaHasManyVSelect from '@/views/components/util/YonaHasManyVSelect.vue'
import LogicBuilder from '@/views/components/pay_condition/LogicBuilder.vue'

export default {
  components: {
    YonaEditDialog,
    YonaHasManyVSelect,
    LogicBuilder,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isFormOpen = ref(false)
    const isSubmitting = ref(false)
    const rebateSystemUnits = ref([])
    const vendingRebates = ref([])
    const salesRebates = ref([])
    const referringRebates = ref([])
    const groupHeader = ref()
    const defaultCurrentFormValue = {
      id: null,
      name: '',
      description: '',
      evaluationCycleSpan: null,
      vendingRebatesAttributes: [],
      salesRebatesAttributes: [],
      referringRebatesAttributes: [],
    }
    const currentForm = ref(cloneDeep(defaultCurrentFormValue))
    const groupPositions = {
      daily: 1,
      weekly: 2,
      half_monthly: 3,
      monthly: 4,
    }
    const groupNames = Object.fromEntries(Object.entries(groupPositions).map(a => a.reverse()))
    const vendingPointConditions = ref([])
    const salesConditions = ref([])
    const contributionRatioConditions = ref([])
    const vendingSizeConditions = ref([])
    const referringSizeConditions = ref([])

    const rebatesByType = computed(() => {
      return {
        VendingRebate: vendingRebates.value,
        SalesRebate: salesRebates.value,
        ReferringRebate: referringRebates.value,
      }
    })

    const rebateSystemUnitRebatesAttributes = computed(() => {
      const {
        vendingRebatesAttributes,
        salesRebatesAttributes,
        referringRebatesAttributes,
      } = currentForm.value

      return [
        ...map(vendingRebatesAttributes, rebateSystemUniRebate => { return { ...rebateSystemUniRebate, rebateType: 'VendingRebate' } }),
        ...map(salesRebatesAttributes, rebateSystemUniRebate => { return { ...rebateSystemUniRebate, rebateType: 'SalesRebate' } }),
        ...map(referringRebatesAttributes, rebateSystemUniRebate => { return { ...rebateSystemUniRebate, rebateType: 'ReferringRebate' } }),
      ]
    })

    const isValid = computed(() => {
      return {
        name: currentForm.value.name,
        // eslint-disable-next-line no-underscore-dangle
        rebates: rebateSystemUnitRebatesAttributes.value.length > 0 && some(rebateSystemUnitRebatesAttributes.value, o => !o._destroy),
      }
    })

    const treeviewItems = item => {
      return flatMap(['VendingRebate', 'SalesRebate', 'ReferringRebate'], type => {
        const rebates = item[`${type.charAt(0).toLowerCase() + type.slice(1)}sAttributes`]
        if (rebates.length <= 0) return []

        return {
          id: `unit-${item.id}-${type}`,
          name: `${{ VendingRebate: '商品', SalesRebate: '売上', ReferringRebate: '紹介' }[type]}バック（${rebates.length}）`,
          children: [
            { rebates: map(rebates, data => rebatesByType.value[type].find(r => +r.id === +data.rebateId)) },
          ],
        }
      })
    }

    const setRebateSystemUnitRebatesByType = item => {
      const obj = {
        vendingRebatesAttributes: [],
        salesRebatesAttributes: [],
        referringRebatesAttributes: [],
      }

      each(item.attributes.rebateSystemUnitRebates.data, data => {
        const toPush = { id: data.id, ...data.attributes }

        switch (data.attributes.rebateType) {
          case 'VendingRebate':
            obj.vendingRebatesAttributes.push(toPush)
            break

          case 'SalesRebate':
            obj.salesRebatesAttributes.push(toPush)
            break

          case 'ReferringRebate':
            obj.referringRebatesAttributes.push(toPush)
            break

          default:
            break
        }
      })

      return obj
    }

    const cancelForm = () => {
      isFormOpen.value = false
      isSubmitting.value = false
      currentForm.value = cloneDeep(defaultCurrentFormValue)
    }

    const submit = async () => {
      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        name,
        description,
        evaluationCycleSpan,
        logicsAttributes,
      } = currentForm.value

      const res = await RebateSystemUnitApi[`${fnVerb}RebateSystemUnit`]({
        id,
        name,
        description,
        evaluationCycleSpan,
        logicsAttributes,
        rebateSystemUnitRebatesAttributes: rebateSystemUnitRebatesAttributes.value,
      })

      if (res?.data) {
        const { data } = res.data.rebateSystemUnit
        const rebateSystemUnit = {
          ...data,
          ...setRebateSystemUnitRebatesByType(data),
          groupPosition: groupPositions[data.attributes.evaluationCycleSpan],
        }

        if (isUpdate) {
          const rebateSystemUnitIdx = findIndex(rebateSystemUnits.value, o => +o.id === +rebateSystemUnit.id)
          rebateSystemUnits.value.splice(rebateSystemUnitIdx, 1, rebateSystemUnit)
        } else {
          rebateSystemUnits.value.push(rebateSystemUnit)
        }

        vm.$toast.success(isUpdate ? 'バックユニットを更新しました' : 'バックユニットを追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async id => {
      const res = await RebateSystemUnitApi.deleteRebateSystemUnit(id)

      if (res) {
        const rebateSystemUnitIdx = findIndex(rebateSystemUnits.value, o => +o.id === +id)
        rebateSystemUnits.value.splice(rebateSystemUnitIdx, 1)
        vm.$toast.success('バックユニットを削除しました')
      }
    }

    const getRebateSystemUnits = async () => {
      const res = await RebateSystemUnitApi.getRebateSystemUnits()

      if (res?.data) {
        rebateSystemUnits.value = [
          ...map(res.data.rebateSystemUnits.data, rebateSystemUnit => {
            return {
              ...rebateSystemUnit,
              ...setRebateSystemUnitRebatesByType(rebateSystemUnit),
              groupPosition: groupPositions[rebateSystemUnit.attributes.evaluationCycleSpan],
            }
          }),
        ]
      }
    }

    const getRebates = async () => {
      const res = await RebateApi.getRebates()

      if (res?.data) {
        vendingRebates.value = [...res.data.vendingRebates.data]
        salesRebates.value = [...res.data.salesRebates.data]
        referringRebates.value = [...res.data.referringRebates.data]
      }
    }

    const getVendingPointConditions = async () => {
      const res = await VendingPointConditionApi.getVendingPointConditions()

      if (res?.data) {
        vendingPointConditions.value = [...res.data.vendingPointConditions.data]
      }
    }

    const getSalesConditions = async () => {
      const res = await SalesConditionApi.getSalesConditions()

      if (res?.data) {
        salesConditions.value = [...res.data.salesConditions.data]
      }
    }

    const getContributionRatioConditions = async () => {
      const res = await ContributionRatioConditionApi.getContributionRatioConditions()

      if (res?.data) {
        contributionRatioConditions.value = [...res.data.contributionRatioConditions.data]
      }
    }

    const getVendingSizeConditions = async () => {
      const res = await VendingSizeConditionApi.getVendingSizeConditions()

      if (res?.data) {
        vendingSizeConditions.value = [...res.data.vendingSizeConditions.data]
      }
    }

    const getReferringSizeConditions = async () => {
      const res = await ReferringSizeConditionApi.getReferringSizeConditions()

      if (res?.data) {
        referringSizeConditions.value = [...res.data.referringSizeConditions.data]
      }
    }

    initWith([
      getRebateSystemUnits(),
      getRebates(),
      getVendingPointConditions(),
      getSalesConditions(),
      getContributionRatioConditions(),
      getVendingSizeConditions(),
      getReferringSizeConditions(),
    ])

    return {
      // data
      isFormOpen,
      isLoading,
      currentForm,
      isSubmitting,
      rebateSystemUnits,
      headers: [
        {
          text: '名前',
          value: 'attributes.name',
          groupable: false,
        },
        // {
        //   text: '説明',
        //   value: 'attributes.description',
        //   sortable: false,
        //   groupable: false,
        // },
        {
          text: '評価サイクル',
          value: 'groupPosition',
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
          groupable: false,
        },
      ],
      evaluationCycleSpans: [
        { value: null, text: '随時' },
        { value: 'daily', text: '日ごと' },
        { value: 'weekly', text: '週ごと' },
        { value: 'half_monthly', text: '半期ごと' },
        { value: 'monthly', text: '月ごと' },
      ],
      evaluationCycleSpanColors: {
        daily: 'accent',
        weekly: 'success',
        half_monthly: 'warning',
        monthly: 'secondary',
      },
      groupTexts: {
        daily: '日ごと',
        weekly: '週ごと',
        half_monthly: '半期ごと',
        monthly: '月ごと',
      },
      groupNames,
      groupHeader,
      vendingPointConditions,
      salesConditions,
      contributionRatioConditions,
      vendingSizeConditions,
      referringSizeConditions,
      defaultCurrentFormValue,

      // computed
      rebatesByType,
      rebateSystemUnitRebatesAttributes,
      isValid,

      // methods
      treeviewItems,
      cancelForm,
      submit,
      destroy,
      setRebateSystemUnitRebatesByType,

      icons: {
        mdiTrashCanOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiCommentQuoteOutline,
        mdiCubeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(v-data-table__expanded__content) using ($material) {
  td {
    background-color: map-deep-get($material, 'background');
  }
}

.v-data-table__expanded__content td {
  width: 100vw;
  vertical-align: top;

  &>div:first-child {
    padding: 12px 0 32px 0;

    &>.col {
      background-color: rgba(0,0,0,.05);
    }
  }
}
</style>
